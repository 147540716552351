import eitaaIcon from '../../img/icons/eitaa-dark.svg';
import eitaaIconFooter from '../../img/icons/eitaa.png';

export const DETAILS = {
    name: " آوا استایل",
    footerName: "آوا استایل",
    description: "",
    shortAbout: "ما در آوا استایل متخصص در طراحی و عرضه لباس‌های کودک با کیفیت و مدرن هستیم.در فروشگاه ما، مجموعه‌ای وسیع و متنوع از لباس‌های کودک را ارائه می‌دهیم. از جمله لباس‌های روزمره، لباس‌های مجلسی، لباس‌های ورزشی، لباس‌های خواب و لباس‌های استحمام. همچنین، ما لباس‌های زمستانی، بهاره و تابستانی را نیز در فروشگاهمان داریم. بنابراین، شما می‌توانید بر اساس فصل و نیازهای خاص کودک خود، انتخاب کنید.\n" +
        "\n",
    veryShortAbout: "ما در آوا استایل متخصص در طراحی و عرضه لباس‌های کودک با کیفیت و مدرن هستیم.در فروشگاه ما، مجموعه‌ای وسیع و متنوع از لباس‌های کودک را ارائه می‌دهیم. از جمله لباس‌های روزمره، لباس‌های مجلسی، لباس‌های ورزشی، لباس‌های خواب و لباس‌های استحمام. همچنین، ما لباس‌های زمستانی، بهاره و تابستانی را نیز در فروشگاهمان داریم. بنابراین، شما می‌توانید بر اساس فصل و نیازهای خاص کودک خود، انتخاب کنید.\n" +
        "\n",
    link: "https://aavastyle.ir",
    mobile: ["09357195494"],
    phone: [],
    email: [],
    postalCode: [],
    address: "فارس ، شهر صدرا ، فاز2 ، بلوار میثاق",
    supportDescription: "",
    workingHours: [],
    "__choose among these__": "",
    mapIframeSrc: "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1619.851983794734!2d52.464064794664694!3d29.821454250624505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjnCsDQ5JzE3LjQiTiA1MsKwMjcnNTEuMCJF!5e0!3m2!1sen!2snl!4v1706450705739!5m2!1sen!2snl",
    socialMedia: [
        {
            media: "whatsApp",
            title: 'واتس اپ',
            value: '09357195494',
            name: 'whatsapp',
            link: 'https://api.whatsapp.com/send/?phone=09357195494&text&app_absent=0',
            icon: <i className="lab la-whatsapp"/>,
            isPrimary: false
        },
        {
            media: "telegram",
            title: 'تلگرام',
            value: '09357195494',
            name: 'telegram',
            link: 'https://t.me/+989357195494',
            icon: <i className="lab la-telegram-plane"/>,
            isPrimary: true
        },
        {
            media: "instagram",
            title: 'اینستاگرام',
            value: 'lebas_koodak.avaa',
            name: 'instagram',
            link: "https://instagram.com/avastyle1.ir",
            icon: <i className="lab la-instagram"/>,
            isPrimary: true
        },
        {
            media: "eitaa",
            title: 'ایتا',
            value: '09357195494',
            name: 'eitaa',
            link: 'https://eitaa.com/09357195494',
            icon: <img src={eitaaIconFooter} alt='ایتا' width={'50%'}/>,
            isPrimary: false
        },
        {
            media: "eitaaDark",
            title: 'ایتا',
            value: '09357195494',
            name: 'eitaa',
            link: 'https://eitaa.com/09357195494',
            icon: <img src={eitaaIcon} alt='ایتا' width={'75%'}/>,
            isPrimary: false
        },
        // {
        //     title: 'ایمیل',
        //     value: 'Volvotak@gmail.com',
        //     name: 'email',
        //     link: 'mailto:Volvotak@gmail.com',
        //     icon: <i className="las la-envelope"></i>,
        //     isPrimary: true
        // },
        // {
        //     title: 'تماس',
        //     value: '09133377432',
        //     name: 'phone',
        //     link: 'tel:989133377432',
        //     icon: <PhoneCall/>,
        //     isPrimary: true
        // },
        /*{
            title: 'آپارات',
            value: 'Alo_aghsat',
            name: 'aparat',
            link: 'https://www.aparat.com/Alo_aghsat',
            icon: <SiAparat size={25}/>,
            isPrimary: true
        },
        {
            title: 'تماس',
            value: '02636634893',
            name: 'phone',
            link: 'tel:+982636634893',
            icon: <PhoneCall/>,
            isPrimary: false
        },
        {
            title: 'سروش پلاس',
            value: '09331074475',
            name: 'soroushPlus',
            link: '',
            icon: <img src={soroushIcon} alt='سروش'/>,
            isPrimary: false
        },
        {
            title: 'سروش پلاس',
            value: '09332094475',
            name: 'soroushPlus',
            link: '',
            icon: <img src={soroushIcon} alt='سروش'/>,
            isPrimary: false
        },
        {
            title: 'سروش پلاس',
            value: '09330324475',
            name: 'soroushPlus',
            link: '',
            icon: <img src={soroushIcon} alt='سروش'/>,
            isPrimary: false
        },
        {
            title: 'ایتا',
            value: '09331074475',
            name: 'eitaa',
            link: '',
            icon: <img src={eitaaIcon} alt='ایتا'/>,
            isPrimary: false
        },*/
    ],
    services: [],
    brands: [
        /*{
            name: 'Apple',
            image: <img src={appleImage} alt='apple'/>,
        },*/
    ],
    "enamadLink": "",
    "samandehiLink": "",
    "ZarinpalLink": "https://www.zarinpal.com/webservice/TrustCode"
}
